<template>
    <div>
        <cp-menu-items
            :projectsList="projectsList"
            :itemsList="itemsList"
            :currentProjectId="currentProjectId"
            @action="action"
            @switchProject="switchProject"
        >
        </cp-menu-items>
        <form
            ref="exit"
            method="GET"
            action="api/return"
        >
            <input
                type="hidden"
                name="_token"
                :value="token"
            >
        </form>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import CpMenuItems from '@js/client-portal/vue-components/layout/menu/cp-menu-items.vue';
import interactsWithProjectSwitch from '@js/client-portal/vue-mixins/interactsWithProjectSwitch.js';

export default {
    name: 'CpMenuSearch',
    components: {
        CpMenuItems,
    },
    mixins: [
        interactsWithProjectSwitch,
    ],
    emits: ['close'],
    computed: {
        itemsList() {
            let list = [
                {
                    title: trans('client-portal.common.return'),
                    iconClass: 'far fa-sign-out-alt',
                    action: 'exitClientPortal',
                },
            ];

            if (this.currentProjectId) {
                list.unshift({
                    title: trans('client-portal.common.settings'),
                    iconClass: 'far fa-cog',
                    action: 'showSettingsModal',
                });

                const project = _.find(this.nal.projects, ['brief_id', this.currentProjectId]);
                if (project?.owner?.id === this.nal.user.id || this.nal.user.permissionGroup?.nal_name === 'admins') {
                    list.splice(1, 0, {
                        title: trans('client-portal.archive.setting'),
                        iconClass: 'far fa-archive',
                        action: 'archiveClientPortal',
                    });
                }
            }

            return list;
        },
    },
    methods: {
        showSettingsModal() {
            this.$emit('close');
            this.createModal({
                data: {
                    component: defineAsyncComponent(() =>
                        import('@js/client-portal/vue-components/settings/cp-settings.vue')),
                    style: { width: '700px' },
                },
            });
        },
        token() {
            return window.token.content;
        },
        exitClientPortal() {
            this.$refs.exit.submit();
        },
        archiveClientPortal() {
            this.createModal({
                data: {
                    component: defineAsyncComponent(() =>
                        import('@js/client-portal/vue-components/page/settings/archive-portal.vue')),
                    style: { height: '400px' },
                },
            });
        },
    },
};
</script>